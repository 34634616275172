import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useInstallationStore, useNumberOfDaysStore } from "src/store";
import { ReactHookFormControl } from "src/types";
import { numberOfDaysSelection } from "src/utils/constants";

export type RangePickerSelectedDays = {
  from: string;
  to: string;
};

type Props = {
  control: ReactHookFormControl;
};

const daysDropdownValues = numberOfDaysSelection();

export default function NumberOfDaysSelector({ control }: Props) {
  const { installationDetails, setInstallationDetails } =
    useInstallationStore();
  const { numberOfDays, setNumberOfDays } = useNumberOfDaysStore();
  const { t } = useTranslation();

  const isDateEmpty = !installationDetails.dateRange.from;

  return (
    <div className="form-group">
      <Controller
        control={control}
        name="numberOfDays"
        render={({ field: { ref, onChange } }) => (
          <>
            <label htmlFor="numberOfDays">
              {t("installations:inputs:numberOfDays:label")}:
            </label>
            <Select
              ref={ref}
              id="numberOfDays"
              placeholder={t("installations:inputs:numberOfDays:label")}
              options={daysDropdownValues}
              value={numberOfDays}
              isDisabled={isDateEmpty}
              onChange={(e) => {
                let dateRange = {
                  from: installationDetails.dateRange.from,
                  to: e
                    ? dayjs(installationDetails.dateRange.from)
                        .add(+e.value - 1, "day")
                        .format("YYYY-MM-DD")
                    : "",
                };
                setInstallationDetails({
                  dateRange,
                });

                e && setNumberOfDays(e);
                return onChange(
                  daysDropdownValues.map((option) => option.value)
                );
              }}
            />
          </>
        )}
      />
    </div>
  );
}
