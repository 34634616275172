import create from "zustand";

type DiscountState = {
  discountPercentage: number;
  setDiscountPercentage: (discountP: number) => void;
};

const useDiscountStore = create<DiscountState>((set) => ({
  discountPercentage: 0,
  setDiscountPercentage: (data) =>
    set((state) => ({
      ...state,
      discountPercentage: data,
    })),
}));

export { useDiscountStore };
