import { yupResolver } from "@hookform/resolvers/yup";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ContactDetailsForm,
  DateRange,
  PaypalCheckoutButton,
  StepOneForm,
} from "src/components";
import { paypalClientId } from "src/utils/constants";
import { FormSteps } from "./types";
import { useSchema } from "./utils/helpers";
import { PARENT_WORDPRESS_URL } from "./utils/urls";
import { useGlobalStore } from "./store";

function App() {
  const { t } = useTranslation();
  const methods = useForm({
    mode: "all",
    resolver: yupResolver(useSchema()),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    reset,
    setError,
  } = methods;

  const [currentStep, setCurrentStep] = useState<FormSteps>(1);
  const { global } = useGlobalStore();

  useEffect(() => {
    /**
     * To change height of iframe dynamically according to height of form
     */
    const scrollHeight = document.documentElement.scrollHeight;
    // window.parent.postMessage({ scrollHeight }, LOCAL_PARENT_URL); // uncomment this for testing
    window.parent.postMessage({ scrollHeight }, PARENT_WORDPRESS_URL);
  }, [currentStep]);

  return (
    <PayPalScriptProvider
      options={{
        "client-id": paypalClientId as string,
        currency: "EUR",
        components: "buttons",
      }}
    >
      <FormProvider {...methods} >
      {currentStep === 1 && (
        <StepOneForm
          setCurrentStep={setCurrentStep}
          errors={errors}
          control={control}
          reset={reset}
        />
      )}
      {currentStep === 2 && (
        <>
          <div
            className="wrapper"
            style={global.isSubmitting ? { pointerEvents: "none" } : undefined}
          >
            <DateRange register={register} errors={errors} control={control} />
            <ContactDetailsForm
              register={register}
              errors={errors}
              control={control}
              setError={setError}
            />
          </div>
          <p style={{ marginLeft: "1rem", fontSize: ".8rem" }}>
            <b>{t("warnings:allRequired")}</b>
          </p>
          <PaypalCheckoutButton
            handleSubmit={handleSubmit}
            errors={errors}
            trigger={trigger}
          />
        </>
      )}
      </FormProvider>
      <ToastContainer />
    </PayPalScriptProvider>
  );
}

export default App;
