export default function InputLoading() {
  return (
    <div className="code-loading-container">
      <div className="code-loading-wrapper">
        <span className="code-loading-dot-1"></span>
        <span className="code-loading-dot-2"></span>
        <span className="code-loading-dot-3"></span>
      </div>
    </div>
  );
}
