import * as queryString from "query-string";
import { Dispatch, SetStateAction, useEffect } from "react";
import { ProgressBar } from "react-loader-spinner";
import { useAsync } from "src/hooks";
import { getAllCities } from "src/services";
import { useGlobalStore, useInstallationStore } from "src/store";
import {
  APIResponse,
  CityResponse,
  FormSteps,
  ReactHookFormControl,
  ReactHookFormReset,
} from "src/types";
import {
  CitySelector,
  DateSelector,
  FormTitle,
  NumberOfDaysSelector,
} from "./form";
import { makeRequest } from "src/utils/makeRequest";

export type RangePickerSelectedDays = {
  from: string;
  to: string;
};

type Props = {
  setCurrentStep: Dispatch<SetStateAction<FormSteps>>;
  errors: any;
  control: ReactHookFormControl;
  reset: ReactHookFormReset;
};

export default function StepOneForm({
  setCurrentStep,
  errors,
  control,
  reset,
}: Props) {
  const {
    installationDetails,
    setInstallationDetails,
    resetInstallationDetails,
  } = useInstallationStore();

  const {
    global,
    setBasePrice,
    setPermissionPrice,
    setWithOutsideLiftPrice,
    setCities,
  } = useGlobalStore();

  const { value: citiesData } =
    useAsync<APIResponse<CityResponse[]>>(getAllCities);

  useEffect(() => {
    if (window.location.search && global.cities) {
      let searchParam = queryString.parse(window.location.search);
      citiesData?.data?.forEach((city) => {
        if (searchParam.cityId === city.slug) {
          resetInstallationDetails();
          const searchedCity = global.cities.find(
            (city) => city.slug === searchParam.cityId
          )!;
          setBasePrice(searchedCity?.basePrice);
          setPermissionPrice(searchedCity?.permissionPrice);
          setWithOutsideLiftPrice(searchedCity?.withOutsideLiftPrice);
          const defaultCity = {
            value: city.id,
            label: city.name,
            basePrice: city.basePrice,
            withOutsideLiftPrice: city.withOutsideLiftPrice,
            id: city.id,
          };

          setInstallationDetails({ city: searchParam ? defaultCity : "" });

          // inject default value for city
          let defaultValues: any = { city: null };
          defaultValues.city = global.cities;
          reset({ ...defaultValues });
        }
      });
    }
  }, [global.cities]);

  useEffect(() => {
    if (citiesData?.data?.length) {
      const cities = citiesData.data.map((city) => ({
        label: city.name,
        value: city.id,
        basePrice: city.basePrice,
        permissionPrice: city.permissionPrice,
        withOutsideLiftPrice: city.withOutsideLiftPrice,
        labelWithoutLowestPrice: city.name,
        labelWithLowestPrice: `${city.name} ab ${city.lowestPrice}€`,
        netto: (city.lowestPrice / 1.19).toFixed(2),
        slug: city.slug,
      }));

      setCities(cities);
    }
  }, [citiesData, setCities]);

  if (window.location.search && !installationDetails.city) {
    return (
      <div className="spinner-wrapper">
        <ProgressBar
          height="80"
          width="80"
          ariaLabel="progress-bar-loading"
          wrapperClass="progress-bar-wrapper"
          borderColor="blue"
          barColor="blue"
        />
      </div>
    );
  }
  const isDateEmpty = !installationDetails.dateRange.from;

  const handleButtonClick = () => {
    setCurrentStep(2);

    const data = {
      eventType: "click",
      targetName: "Button Weiter",
      metadata: {
        page: "Step 1",
      },
    };

    makeRequest("/api/analytics/track-event", {
      method: "POST",
      data,
    });
  };

  return (
    <div className="dateranger">
      <div className="border">
        <FormTitle />
        <CitySelector control={control} errors={errors} />
        <DateSelector isStepOne={true} />
        <NumberOfDaysSelector control={control} />
      </div>
      <button
        className={`payment-btns prepayment ${isDateEmpty ? "disabled" : ""}`}
        style={{
          alignSelf: "center",
          marginTop: "1rem",
          marginLeft: "0",
        }}
        disabled={isDateEmpty}
        onClick={handleButtonClick}
      >
        Weiter
      </button>
    </div>
  );
}
