import axios from "axios";
import { baseURL } from "./constants";

const api = axios.create({
  baseURL: baseURL,
  //   withCredentials: true,
});

export async function makeRequest<T = any>(url: any, options?: any) {
  try {
    const res = await api(url, options);
    return res.data as T;
  } catch (error: any) {
    return await Promise.reject(error?.response?.data?.message ?? "Error");
  }
}
